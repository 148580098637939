"use client";

import { usePathname } from "next/navigation";
import NavBar from "@/components/desktop/NavBar";
import Footer from "@/components/desktop/Footer";
import dynamic from "next/dynamic";

// Import LocalSchema with client-side only rendering
const LocalSchema = dynamic(() => import("@/components/desktop/LocalSchema"), {
  ssr: false,
});

export default function DesktopLayout({ children }) {
  const pathname = usePathname();
  const isAdminRoute = pathname.startsWith("/dv/admin");

  return (
    <>
      <LocalSchema />
      <div className="flex flex-col min-h-screen">
        {!isAdminRoute && <NavBar />}
        <main className="grow">{children}</main>
        {!isAdminRoute && <Footer />}
      </div>
    </>
  );
}
