import React from "react";
import Link from "next/link";

export default function Footer() {
  return (
    <footer className="bg-mad-blue text-mad-white py-8">
      <div className="max-w-6xl mx-auto px-6 flex flex-col md:flex-row md:justify-between gap-8">
        {/* Company Info */}
        <div className="flex-1">
          <h2 className="text-xl font-bold">Mayes Auto Detailing</h2>
          <p className="mt-2 text-sm text-gray-300">
            Premium auto detailing services to protect and enhance your vehicle.
          </p>
        </div>

        {/* Navigation Links */}
        <div className="flex-1">
          <h2 className="text-lg font-semibold">Quick Links</h2>
          <ul className="mt-4 space-y-1">
            <FooterLink href="/">Home</FooterLink>
            <FooterLink href="/dv/services">Services</FooterLink>
            <FooterLink href="/dv/contact">Contact Us</FooterLink>
            <FooterLink href="/dv/book-now">Book Now</FooterLink>
          </ul>
        </div>

        {/* Service Areas */}
        <div className="flex-1">
          <h2 className="text-lg font-semibold">Service Areas</h2>
          <ul className="mt-4 space-y-1">
            <FooterLink href="/dv/service-area/chattanooga-car-detailing">
              Chattanooga, TN
            </FooterLink>
            <FooterLink href="/dv/service-area/east-ridge-car-detailing">
              East Ridge, TN
            </FooterLink>
            <FooterLink href="/dv/service-area/cleveland-car-detailing">
              Cleveland, TN
            </FooterLink>
            <FooterLink href="/dv/service-area/collegedale-car-detailing">
              Collegedale, TN
            </FooterLink>
            <FooterLink href="/dv/service-area/red-bank-car-detailing">
              Red Bank, TN
            </FooterLink>
            <FooterLink href="/dv/service-area/soddy-daisy-car-detailing">
              Soddy Daisy, TN
            </FooterLink>
            <FooterLink href="/dv/service-area/ooltewah-car-detailing">
              Ooltewah, TN
            </FooterLink>
          </ul>
        </div>

        {/* Contact Info */}
        <div className="flex-1">
          <h2 className="text-lg font-semibold">Contact Us</h2>
          <ul className="mt-4 space-y-1">
            <FooterLink href="https://www.google.com/maps/dir/?api=1&destination=Mayes+Auto+Detailing">
              Address: 3921 Weldon Dr, Chattanooga, TN 37412
            </FooterLink>
            <FooterLink href="tel:4234970881">Phone: (423) 497-0881</FooterLink>
            <FooterLink href="mailto:info@mayesautodetailing.com">
              Email: info@mayesautodetailing.com
            </FooterLink>
          </ul>
        </div>
      </div>

      <div className="mt-8 border-t border-mad-red pt-4 text-center text-xs text-gray-400">
        &copy; {new Date().getFullYear()} Mayes Auto Detailing. All Rights
        Reserved.
      </div>
    </footer>
  );
}

function FooterLink({ href, children }) {
  return (
    <li>
      <Link
        href={href}
        className="block p-1 hover:text-mad-red transition-colors text-sm"
      >
        {children}
      </Link>
    </li>
  );
}
